.events-list-container {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  padding: 20px;
  position: relative;
  width: 100%;
  height: 50vh;
}
@media (min-width: 992px) {
  .events-list-container {
    float: right;
    right: 0;
    top: 0;
    height: 89vh;
    width: 40%; }
}

.events-list-filter-button {
  display: inline-block;
  width: 100%;
  margin: 10px 0 0 0;
  background-color: rgb(238, 238, 238);
  border: none;
  padding: 10px;
  color: #000;
}

.events-list-container select{
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
  /* for IE10 */
  .events-list-container select::-ms-expand {
    display: none;
  }
}

.events-list-filter-button-text {
  float: left;
  color: #282c34;
}

.events-list-event-listing {
  scroll-behavior: smooth;
  overflow: scroll;
  height: 100%;
  margin: 15px 0;
  flex-grow: 1;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

//.scroll-snap {
//  scroll-snap-type: y mandatory;
//}

/* Hide scrollbar for Chrome, Safari and Opera */
.events-list-event-listing::-webkit-scrollbar {
  display: none;
}