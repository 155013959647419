.event-card-container {
  scroll-snap-align: start;
}

.event-card-container .container {
  padding-left: 0;
  margin-left: 0;
}

.event-card-title {
  padding-top: 15px;
  font-size: 1.5rem;
}

.event-card-image {
  margin-top: 10px;
  width: 100%;
}


.event-card-description {
  margin-top: 15px;
  text-align: justify;
}

.event-card-organizer {
  display: inline-block;
}

.event-card-price {
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
}

.event-card-organizer-description-card {
  padding-right: 8px;
  display: inline-block;
}

.event-card-price-club-information {
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
}

.bi-ticket-perforated-fill {
  transform: rotate(45deg);
}

.event-card-club {
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
}

.event-card-learn-more-button {
  background-color: black;
  color: white;
  font-weight: bold;
  width: 100%;
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.event-card-learn-more-button:hover {
  background-color: white;
  color: black;
  .event-card-learn-more-link {
    color: black;
  }
}

.event-card-learn-more-link {
  text-decoration: none;
  color: white;
}

.event-club-information-card-description-align {
  //padding-left: 8px;
}


.event-card-date {
  color: hsl(350deg 87% 55%)
}

.bootstrap-icon {
  margin-right: 10px;
}

