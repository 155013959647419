.payment-method-page-first-header {
  padding-bottom: 10px;
}

.payment-method-page-third-header {
  padding-top: 10px;
}

.payment-method-container {
  box-sizing: border-box;
  padding: 70px 30px;
}

.payment-method-row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  margin: 0 -16px;
}

.payment-method-col-25 {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
}

.payment-method-col-50 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
}

.payment-method-col-75 {
  -ms-flex: 75%; /* IE10 */
  flex: 75%;
}

.payment-method-col-25,
.payment-method-col-50,
.payment-method-col-75 {
  padding: 0 16px;
}

.payment-method-inner-container {
  background-color: white;
  padding: 5px 20px 15px 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;

}

.payment-method-inner-tickets-container {
  background-color: white;
  padding: 5px 20px 0 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;
}

.input-type-text {
  display: inline-block;
  width: 100%;
  margin: 0 0 10px 0;
  background-color: rgb(238, 238, 238);
  border: none;
  padding: 10px;
  color: #000;

  //width: 100%;
  //background-color: lightgray;
  //margin-bottom: 20px;
  //padding: 12px;
  //border: 1px solid #000;
  //border-radius: 3px;
}

.input-type-text-description {
  display: inline-block !important;
  padding-left: 10px;
}

.payment-method-container label {
  margin-bottom: 10px;
  display: block;
}

.icon-container {
  margin-bottom: 20px;
  padding: 7px 0;
  font-size: 24px;
}

.payment-method-continue-button {
  background-color: #000;
  font-weight: bold;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid black;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
}

.payment-method-continue-button:hover {
  background-color: white;
  color: black
}

.payment-method-container a {
  color: #2196F3;
}

.payment-method-container hr {
  border: 1px solid lightgrey;
}

.payment-method-container span.price {
  float: right;
  color: grey;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (also change the direction - make the "cart" column go on top) */
@media (max-width: 800px) {
  .payment-method-row {
    flex-direction: column-reverse;
  }
  .payment-method-col-25 {
    margin-bottom: 20px;
  }
}