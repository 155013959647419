.events-list-loading-container {
  background-color: #fff;
  border-radius: 10px;
  display: block;
  padding: 20px;
  position: relative;
  width: 100%;
  height: 50vh;
}
@media (min-width: 992px) {
  .events-list-loading-container {
    float: left;
    right: 0;
    top: 0;
    height: 85vh;
    width: 40%; }
}

.events-list-search-field {
  display: inline-block;
  width: 100%;
  margin: 10px 0 0 0;
  background-color: rgb(238, 238, 238);
  border: none;
  padding: 10px;
  color: #000;
}

//.events-list-event-listing {
//  overflow: scroll;
//  height: 100%;
//  margin: 15px 0;
//  flex-grow: 1;
//  /* Hide scrollbar for IE, Edge and Firefox */
//  -ms-overflow-style: none;  /* IE and Edge */
//  scrollbar-width: none;  /* Firefox */
//}

//.scroll-snap {
//  scroll-snap-type: y mandatory;
//}

/* Hide scrollbar for Chrome, Safari and Opera */
//.events-list-event-listing::-webkit-scrollbar {
//  display: none;
//}

.event-list-loading-learn-more-button {
  background-color: rgb(20, 20, 20);
  color: rgb(20, 20, 20);
  font-weight: bold;
  width: 100%;
  border: 1px solid rgb(20, 20, 20);
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.event-list-loading-image-placeholder {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 20vh;
  background-color: rgb(175, 175, 175);
}