.ticket-title {
  font-size: 3rem;
}

.ticket-details {
  margin-top: 1.2rem;
  font-size: 1.2rem;
}

.ticket-page-container {
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qr-code-container {
  padding: 8px;
  border: 1px solid white;
  border-radius: 10px;
  display: inline-block;
  transform: translate(0, 25%);
  background-color: white;
}

#backgroundVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}