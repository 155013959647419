.map-container {
  margin-top: 0;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 40vh;
}
@media (min-width: 992px) {
  .map-container {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 60%;
  }
}

// remove mapbox logo and info box
.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
  display: none;
}