.map-view-loading-map-container {
  margin-top: 0;
  position: relative;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(238, 238, 238);
}
@media (min-width: 992px) {
  .map-view-loading-map-container {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 60%;
  }
}