.EventDescriptionCard{
  background: rgba( 255, 255, 255, 0.45 );
  //box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  //backdrop-filter: blur( 3.5px );
  //-webkit-backdrop-filter: blur( 3.5px );
  //border-radius: 10px;
  border: 1px solid; //rgba( 255, 255, 255, 0.18 );
  margin-top: 40px;
  padding-top: 10px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 10px;
}

.EventDescriptionCard-style-row {
  padding-left: 8px;
  padding-right: 8px;
}