.EventPageHeader{
  font-weight: bold;
}

.EventPageHeaderDateAndTime{
  font-size: 1rem;
}

.EventPageHeaderTitle{
  font-weight: bold;
}

.EventPageHeaderTitle-Main-title {
  font-weight: bold;
  padding-top: 7px;
  padding-bottom: 7px;
}

.EventPageHeader-Event-Location-Card {
  padding-left: 8px;
  padding-right: 8px;
  font-weight: bold;
}


.event-page-header-button {
  float: right;
}

.event-page-header-button {
  background-color: black;
  color: white;
  font-weight: bold;
  width: 20%;
  border: 1px solid black;
  padding: 2px;
  margin-top: 14px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.event-page-header-button:hover {
  background-color: white;
  color: black;
}

.event-banner{
  padding: 1px;
  //border-radius: 20px;
}