.club-information-card-name{
  font-size: 1rem;
}

.event-info-club-reference{
  color: inherit;
  text-decoration: inherit;
}
.club-visit-button{
  background-color: black;
  color: white;
  font-weight: bold;
  width: 5rem;
  border: 1px solid black;
  padding: 2px;
  margin-inline: 20px;
  margin-top: 5px;
  float:right;
}

.event-card-visit-button-right-align {
  padding-bottom: 8px;
  float:right;
}

.club-visit-button:hover {
  background-color: white;
  color: black;
}

.club-information-header{
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-items: center;
}

.reviews{
  display: flex;
  flex-direction: column;
  //align-items: center;
  padding: 0;
  margin: 0px;
}

.review-list{
  display: flex;
  flex-direction: row;
}

.review-button{
  background-color: black;
  color: white;
  font-weight: bold;
  width: 9rem;
  margin-bottom: 10px;
  border: 1px solid black;
  padding: 2px;
  float:right;
}

.review-button:hover {
  background-color: white;
  color: black;
}

.reviewsTitle{
  font-size: 25px;
}

.review-label{
  font-size: 15px;
  margin: 0px;
  margin-right: 4px;
}

.invenus-rating{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}