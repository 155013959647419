.event-location-card-map-container {
  margin-top: 0;
  display: inline-block;
  position: relative;
  width: 98.7%;
  height: 40vh;
  //border-radius: 20px;
  margin-left:8px;
}

// remove mapbox logo and info box
.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
  display: none;
}