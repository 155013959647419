.reviewer-image{
  width: 100px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.review{
  border: 1px solid; //rgba( 255, 255, 255, 0.18 );
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 10px;
}

.review-header{
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 10px;
}

.stars{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.author{
  margin: 0;
  margin-right: 10px;
}